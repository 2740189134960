<template>
  <t-form
    :title="produto.descricao"
    @click-navigation="$emit('click-navigation')"
    :processamento="processamento"
  >
    <t-container vertical-scroll height="100%" class="px-3">
      <t-card>
        <div :class="`${$vuetify.breakpoint.mdAndUp && 'px-6 '}mb-6`">
          <v-sheet height="300" class="d-flex justify-center align-center">
            <img
              v-if="produto.url"
              :src="produto.url"
              :style="`
                max-width: 270px;
                max-height: 270px;
                width: auto;
              `"
            />
            <v-icon v-else size="270" color="primary">mdi-image-off</v-icon>
          </v-sheet>

          <v-sheet class="d-flex flex-column">
            <span class="text-caption grey--text">{{ produto.tipo }}</span>
            <span class="display-1 mb-4">{{ produto.descricao }}</span>
            <v-row class="mb-4">
              <v-col>
                <campo-produto
                  chave="Modalidade"
                  :valor="produto.modalidade"
                  class="mb-2"
                />
                <campo-produto
                  chave="Conteúdo"
                  :valor="produto.conteudo"
                  class="mb-2"
                />
                <campo-produto
                  v-if="produto.tipo === 'Treinamento'"
                  chave="Material"
                  :valor="produto.material"
                  class="mb-2"
                />
              </v-col>
              <v-col>
                <campo-produto
                  chave="Local"
                  :valor="produto.local"
                  class="mb-2"
                />
                <campo-produto
                  chave="Entrega"
                  :valor="produto.entrega"
                  class="mb-2"
                />
              </v-col>
            </v-row>
            <v-btn rounded color="success" @click="adquirirProduto()">
              <v-icon small class="mr-2">mdi-cart</v-icon>
              Adquirir
            </v-btn>
          </v-sheet>
        </div>
        <campo-produto
          chave="Descrição"
          :valor="produto.detalhamento.replace(/\\n/g, '<br />')"
          class="pa-2 mt-6"
          style="white-space: pre-line"
        />
      </t-card>
    </t-container>
  </t-form>
</template>

<script>
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import CampoProduto from './CampoProduto.vue';
import { Atendimento } from '../../../proxy-api/src/services/classes/Atendimento/Atendimento';
import { AtendimentoController } from '../../../proxy-api/src/services/classes/Atendimento/AtendimentoController';
import { Sessao } from '../../../proxy-api/src/services/classes/Autenticacao/Sessao';
import { Storage } from '../../../proxy-api/src/services/classes/Storage/Storage';
import ExibirMensagem from '../../../components/ExibirMensagem';

export default {
  components: {
    TForm,
    TContainer,
    TCard,
    CampoProduto,
  },

  data: function () {
    return {
      produto: {},
      processamento: '',
      sessao: Sessao,
    };
  },

  methods: {
    adquirirProduto() {
      if (this.sessao.isAcessoContato()) {
        ExibirMensagem.info(
          'Sem permissão',
          'Apenas um usuário com acesso cliente pode adquirir um produto.',
        );
        return;
      }
      ExibirMensagem.pergunta(
        'Deseja adquirir este produto?',
        'Ao prosseguir será iniciado um atendimento com setor comercial.',
        () => {
          this.criarAtendimento();
        },
      );
    },

    async criarAtendimento() {
      const atendimento = new Atendimento();
      atendimento.sistema.codigo = this.produto.sistema;
      atendimento.moduloSistema.codigo = this.produto.modulo;
      atendimento.motivo.codigo = this.produto.motivo;
      atendimento.cliente.codigo = this.sessao.usuario.cliente.codigo;
      atendimento.relatadoPeloCliente = `ID: ${this.produto.id}
      Descrição: ${this.produto.descricao}
      Modalidade: ${this.produto.modalidade}
      Conteudo: ${this.produto.conteudo}
      Entrega: ${this.produto.entrega}
      Local: ${this.produto.local}
      Material: ${this.produto.material}`;

      if (this.sessao.isAcessoContato())
        atendimento.contato.codigo = this.sessao.usuario.codigoPessoa;

      const atendimentoController = new AtendimentoController();
      try {
        this.processamento = 'Criando atendimento...';
        await atendimentoController.gravar(atendimento);
        ExibirMensagem.sucesso(
          'Atendimento criado com sucesso!',
          'Um consultor de vendas da Tek-System irá entrar em contato.',
          () => {
            this.$emit('click-navigation');
          },
        );
      } catch (error) {
        ExibirMensagem.erroGenerico(error);
      } finally {
        this.processamento = '';
      }
    },
  },
  mounted() {
    this.sessao.json = Storage.getSessao();
  },
};
</script>
