<template>
  <div class="d-flex flex-column mb-3">
    <div class="text-caption grey--text ma-0 pa-0">{{ chave }}</div>
    <div class="ma-0 pa-0">{{ valor }}</div>
  </div>
</template>

<script>
export default {
  props: {
    chave: {
      type: String,
      required: true,
    },
    valor: {
      type: String,
      required: true,
    },
  },
};
</script>
