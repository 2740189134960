import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Produto extends Cadastro {
  constructor(persistente) {
    super();
  }

  set json(dados) {
    this.id = dados['ID'];
    this.tipo = dados.Tipo;
    this.descricao = this.descricaoAmigavel(dados.Descricao);
    this.url = dados['URL'];
    this.modalidade = dados.Modalidade;
    this.conteudo = dados.Conteudo;
    this.entrega = dados.Entrega;
    this.local = dados.Local;
    this.material = dados.Material;
    this.sistema = dados.Sistema;
    this.modulo = dados.Modulo;
    this.motivo = dados.Motivo;
    this.detalhamento = dados.Detalhamento;
  }

  descricaoAmigavel(texto) {
    texto = String(texto).toLowerCase();
    texto = String(texto).replace(/_/g, ' ');
    texto = String(texto).replace('integ ', 'integração ');
    texto = String(texto).replace('integracao ', 'integração ');
    texto = String(texto).replace('int ', 'integração ');
    texto = String(texto).replace('exportacao', 'exportação');
    texto = String(texto).replace('tekstore', 'TekStore');
    texto = String(texto).replace('api', 'API');
    texto = String(texto).replace('(', ' (');
    texto = texto.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
    return texto;
  }
}

export { Produto };
