<template>
  <t-form title="Produtos" :processamento="processamento">
    <t-container maxWidth="800">
      <v-row align="center" class="mt-4 mb-2 px-2">
        <v-col cols="1" sm="8">
          <v-chip-group
            active-class="primary--text"
            v-model="tipoSelecionado"
            v-if="$vuetify.breakpoint.smAndUp"
            mandatory
          >
            <v-chip v-for="tipo in tipos" :key="tipo.index">
              <v-icon left small>{{ icone(tipo) }}</v-icon>
              {{ tipo }}
            </v-chip>
          </v-chip-group>

          <v-menu v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="tipo in tipos" :key="tipo.index">
                <v-list-item-title
                  @click="selecionarMenu(tipo)"
                  style="cursor: pointer"
                >
                  <v-icon left small>{{ icone(tipo) }}</v-icon>
                  {{ tipo }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="11" sm="4">
          <v-text-field
            v-model="busca"
            append-icon="mdi-magnify"
            rounded
            placeholder="Buscar..."
            dense
            hide-details
            full-width
          ></v-text-field>
        </v-col>
      </v-row>

      <div
        :style="`
          display: grid;
          grid-template-columns: repeat(${quantidadeColunas()}, 180px);
          grid-gap: 20px;
          align-items: center;
          justify-items: center;
          justify-content: center;
        `"
        class="mb-2 px-2"
      >
        <card-produto
          v-for="produto in produtosFiltrados"
          v-bind:key="produto.index"
          :produto="produto"
          :icone="icone(produto.tipo)"
          @click="abrirProduto(produto)"
          class="mb-2"
        />
      </div>
    </t-container>
  </t-form>
</template>

<script>
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';
import { api } from '../../proxy-api/src/services/api';
import ExibirMensagem from '../../components/ExibirMensagem';
import { Produto } from '../../proxy-api/src/services/classes/Produto/Produto';
import CardProduto from './componentes/CardProduto.vue';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import DetalhamentoProduto from './componentes/DetalhamentoProduto.vue';

export default {
  components: {
    TForm,
    TContainer,
    CardProduto,
  },

  data: function () {
    return {
      busca: '',
      processamento: '',
      produtos: [],
      produtosFiltrados: [],
      tipoSelecionado: 0,
      tipos: [],
    };
  },

  methods: {
    quantidadeColunas() {
      if (this.$vuetify.breakpoint.width > 833) return 4;
      if (this.$vuetify.breakpoint.width > 623) return 3;
      if (this.$vuetify.breakpoint.width > 421) return 2;
      return 1;
    },

    selecionarMenu(tipo) {
      this.tipoSelecionado = this.tipos.indexOf(tipo);
    },

    abrirProduto(produto) {
      const instancia = TFormUtil.instanciar(DetalhamentoProduto);
      instancia.produto = produto;
      TFormUtil.abrir(instancia);
      instancia.$on('click-navigation', () => TFormUtil.fechar(instancia));
    },

    async carregarProdutos() {
      this.processamento = 'Carregando produtos...';
      try {
        const { data } = await api.post('/interpreterEx?unit=PRODUTOSPUBLICOS');
        data.Dados.forEach(registro => {
          const produto = new Produto();
          produto.json = registro;
          this.produtos.push(produto);
        });
      } catch (error) {
        ExibirMensagem.erroGenerico(error);
      } finally {
        this.processamento = '';
      }
    },

    extraiTipos() {
      const tipos = [];
      tipos.push('Tudo');
      this.produtos.forEach(produto => {
        if (!tipos.includes(produto.tipo)) tipos.push(produto.tipo);
      });
      this.tipos = tipos;
    },

    icone(tipo) {
      switch (tipo) {
        case 'Tudo':
          return 'mdi-apps';
        case 'Módulo':
          return 'mdi-cube-outline';
        case 'Funcionalidade':
          return 'mdi-cog';
        case 'Treinamento':
          return 'mdi-school';
      }
    },

    filtrarProdutos() {
      this.produtosFiltrados = this.produtos.filter(produto => {
        if (this.tipos[this.tipoSelecionado] === 'Tudo') return true;
        return produto.tipo === this.tipos[this.tipoSelecionado];
      });
    },

    buscarProduto() {
      this.filtrarProdutos();
      this.produtosFiltrados = this.produtosFiltrados.filter(produto => {
        if (!this.busca) return true;
        return produto.descricao
          .toLowerCase()
          .includes(this.busca.toLowerCase());
      });
    },
  },

  watch: {
    tipoSelecionado() {
      this.filtrarProdutos();
    },

    busca() {
      this.buscarProduto();
    },
  },

  async mounted() {
    await this.carregarProdutos();
    this.extraiTipos();
    this.filtrarProdutos();
  },
};
</script>
