<template>
  <v-hover v-slot="{ hover }">
    <v-card
      width="180"
      height="240"
      :elevation="hover ? 8 : 4"
      :style="hover && 'transform: scale(1.015)'"
      @click="$emit('click')"
    >
      <v-icon small style="position: absolute; top: 7px; right: 7px">{{
        icone
      }}</v-icon>
      <div style="display: grid; grid-template-rows: 175px 45px">
        <div style="display: grid; align-items: center; justify-items: center">
          <img v-if="produto.url" :src="produto.url" width="100" />
          <v-icon v-else size="100" color="primary">mdi-image-off</v-icon>
        </div>
        <div
          style="display: grid; align-items: center; justify-items: center"
          class="mt-3 px-2"
        >
          <span class="text-center text-caption">
            {{ produto.descricao }}
          </span>
          <span> </span>
        </div>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import CampoProduto from './CampoProduto.vue';

export default {
  components: { TCard, CampoProduto },
  props: {
    produto: {
      type: Object,
      required: true,
    },
    icone: {
      type: String,
    },
  },
};
</script>

<style></style>
